html {
  height: 100%;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: "Helvetica Neue", sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
}

.mat-title {
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 1.5rem;
}

.table-no-data > td {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.button-line {
  margin-bottom: 1rem;
}
.button-line > button + button {
  margin-left: 1rem;
}

.text-container {
  text-align: center;

  .container {
    text-align: left;
    display: inline-block;
    line-height: 1.5em;

    .title {
      font-weight: bold;
      padding-bottom: 0.5em;
    }
  }
}
